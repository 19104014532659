import tw, { styled, css } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`flex flex-col md:flex-row`}
  ${({ itemSpacing }) => {
  switch (itemSpacing) {
    case "line":
      return tw`md:-mx-0-1`
    case "no_gap":
      return null
    case "default":
    default:
      return tw`md:-mx-1-5`
  }
}}
`

export const CardWrapper = styled.div`
  ${tw`w-full mb-2-4 md:mb-0`}
  ${({ background }) => {
    return background
      ? css`
          background-color: ${background};
        `
      : null
  }}
  ${({ itemSpacing }) => {
    switch (itemSpacing) {
      case "line":
        return tw`md:mx-0-1`
      case "no_gap":
        return null
      case "default":
      default:
        return tw`md:mx-1-5`
    }
  }}
`
